import { useEffect, useState } from "react"
import AdvanceSearchModal from "../../components/modal/AdvanceSearchModal"
import PropertyCard from "../../components/Property/PropertyCard"
import { Pagination } from "@mui/material"
import PropertyPageMenu from "../../components/Property/PropertyPageMenu"
import { constructUrl, createSearchPayload, propertyListApi, removeSearchParams } from "../../services/propertyService"
import { toast } from "react-toastify"
import { useLocation, useNavigate } from "react-router-dom"
import { nameBasedRoutes } from "../../router/public"
import LocationSearchInput from "../../components/Property/LocationSearchInput"

import { useTranslation } from 'react-i18next'
import { Empty, Select, Skeleton } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { setHeaderClass } from "../../redux/layoutSlice"
import ReactGA4 from "react-ga4"
import Cookies from "universal-cookie"
export const Property3 = () => {
  const { t } = useTranslation();
  const { i18n } = useTranslation()
  const cookies = new Cookies()

  const currentLanguage = i18n?.language ?? cookies.get('userLangKey') ?? 'en';
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const offeringType = useSelector((state) => state?.generalInfo?.platformUsageMap);
  const params = new URLSearchParams(location.search);
  const paramsObj = Object.fromEntries(params);
  const payload = createSearchPayload(paramsObj);
  const navigatePath = nameBasedRoutes?.propertyList?.path;
  const currentPage = parseInt(params.get("page")) || 1;

  const [advanceMenuOpen, setAdvanceMenuOpen] = useState(false)

  const [loading, setLoading] = useState(false);
  const [propertyGridData, setPropertyGridData] = useState([]);
  const [pageSize] = useState(24);
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [propertyTitle, setPropertyTitle] = useState('');
  const [selectedPlace, setSelectedPlace] = useState('');
  const [selectedAddress, setSelectedAddress] = useState(paramsObj?.formattedAddress);
  const sortField = params.get("sort") || 'listingDate,DESC';

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [navigate]);

  const fetchPropertyData = async () => {
    setLoading(true);

    let formData = { isMapView: false, ...payload };

    if (selectedPlace) {
      formData.location = selectedPlace;
    }

    if (formData.polyline) {
      delete formData.polyline;
    }

    const queryParams = { size: pageSize, page: currentPage - 1 };
    let sortString = sortField === 'UNSORTED' ? 'sort=isFeatured,DESC' : `sort=${sortField.replaceAll(': ', ',')}`;

    ReactGA4.event({
      category: "search_property",
      action: "search",
      id: Date.now(),
      item: "List",
      description: JSON.stringify(formData)
    });

    try {
      const response = await propertyListApi(formData, queryParams, sortString);
      setLoading(false);
      setPropertyGridData(response?.data);
      setTotalRowCount(response?.headers["x-total-count"]);

      if (formData.save) {
        navigate(removeSearchParams(navigatePath, "save"), { replace: true });
      }

      if (response?.headers["x-total-count"] < pageSize) {
        navigate(constructUrl(navigatePath, { key: "page", value: 1 }), { replace: true });
      }
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.message || error.message);
    }
  };

  useEffect(() => {
    fetchPropertyData();
    if (selectedPlace !== null) {
      setSelectedAddress(paramsObj?.formattedAddress || '');
    }

    setPropertyTitle(
      paramsObj?.propertyOfferingType && offeringType
        ? `${t("PROPERTIES")} ${t("FOR")} ${offeringType[paramsObj.propertyOfferingType]}`
        : ''
    );
  }, [location.search, selectedPlace, i18n.language]);

  const handlePageChange = (event, value) => {
    navigate(constructUrl(navigatePath, { key: "page", value }), { replace: true });
  };

  useEffect(() => {

    const currentFormattedAddress = selectedPlace?.formatted_address || selectedAddress;
    const currentURLParams = new URLSearchParams(location?.search);
    const existingAddress = currentURLParams.get('formattedAddress');

    if (currentFormattedAddress !== existingAddress) {
      if (currentFormattedAddress) {
        navigate(constructUrl(navigatePath, { key: "formattedAddress", value: currentFormattedAddress }), { replace: true });
      } else {
        navigate(removeSearchParams(navigatePath, "formattedAddress"), { replace: true });
      }
    }
  }, [selectedPlace]);

  // selectedAddress, location.search, navigatePath
  useEffect(() => {
    dispatch(setHeaderClass({ className: "container" }));
  }, [dispatch]);

  const handleSortingChange = (value) => {
    navigate(constructUrl(nameBasedRoutes?.propertyList?.path, { key: "sort", value }), { replace: true });
  };
  return (
    <div className="body_content">
      {/* <LoaderMu loading={loading} /> */}
      {/* Breadcumb Sections */}
      <section className="breadcumb-section pt30 pb0 bgc-f7">
        <div className="container">
          <div className="row">
            <div className="col-xl-6">
              <div className="widget-wrapper">
                <div className="form-style2">
                  <LocationSearchInput
                    value={selectedAddress}
                    setSelectedAddress={setSelectedAddress}
                    placeholder={t("ENTER LOCATION NAME / AREA NAME")}
                    selectedPlace={selectedPlace}
                    // placeholder={'3 Beds Duplex In Khalda'}
                    setSelectedPlace={setSelectedPlace}
                    navigatePath={navigatePath}
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="page_control_shorting ">
                <div className="location-area bootstrap-select user-select pr10  ">
                  {/* <span className='me-2'>{t("SORT BY")} : </span> */}
                  <Select
                    value={sortField ?? 'listingDate,DESC'}
                    options={[
                      { value: "listingDate,DESC", label: t("NEWEST") },
                      { value: "priceInJod,ASC", label: t("PRICE LOW") },
                      { value: "priceInJod,DESC", label: t("PRICE HIGH") }
                    ]}
                    popupClassName={`${currentLanguage === 'ar' ? 'direction-rtl' : ''}`}
                    onChange={handleSortingChange}
                    className='w-150 h-50 form-control'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Property All Lists */}
      <section className="pt0 pb90 bgc-f7">
        <div className="container">
          <PropertyPageMenu searchData={payload} />
          {/* PROPERTY LIST */}
          <div className="row">
            <div className="col-md-12">
              {
                (propertyTitle && propertyTitle !== '') &&
                <h5 className="text-capitalize mb-4">{propertyTitle}</h5>
              }
            </div>
          </div>
          <div className="row">
            {
              loading === true ?
                [...Array(3)].map((element, index) => {
                  return <div key={index + 1} className="col-sm-6 col-xl-4 my-2">
                    <Skeleton.Button active={true} size={"100%"} shape={"square"} block={true} style={{ height: "400px" }} />
                  </div>
                })
                :
                (propertyGridData && propertyGridData?.length > 0) ?
                  propertyGridData.map((element, index) => {
                    return <div key={index + 1} className="col-sm-6 col-lg-4">
                      <PropertyCard propertyDetails={element} isCompare={true} />
                    </div>
                  }) : <Empty description={t("NO PROPERTIES FOUND")} />
            }
          </div>
          {
            (propertyGridData && propertyGridData?.length) ?
              <div className="row">
                <div className="col-12">
                  <div className="mbp_pagination text-center mt30 dataTable_pagination">
                    <Pagination count={Math.ceil(totalRowCount / pageSize)} siblingCount={1} page={parseInt(currentPage)} className="d-inline-block" size="large" onChange={(event, value) => handlePageChange(event, value)} />
                  </div>
                </div>
              </div> : ""
          }
        </div>
        <AdvanceSearchModal isOpen={advanceMenuOpen} setModalOpen={setAdvanceMenuOpen} navigatePath={navigatePath}
        />
      </section>
    </div>
  )
}
