
import { Footer } from "../../components/layouts/Footer/Footer";
import HeaderInfo from "../../components/propertySingle/HeaderInfo";
import PropertyImageSection from "../../components/propertySingle/PropertyImageSection";
import AgentpersonalInfo from "../../components/propertySingle/AgentpersonalInfo";
import TourScheduleForm from "../../components/propertySingle/TourScheduleForm";
import PropertyOverview from "../../components/propertySingle/PropertyOverview"
import PropertyDescription from "../../components/propertySingle/PropertyDescription";
import Tour3d from "../../components/propertySingle/Tour3d";
import PopertyAddress from "../../components/propertySingle/PopertyAddress";
import FeatureAmenitites from "../../components/propertySingle/FeatureAmenitites";
import PropertyVideo from "../../components/propertySingle/PropertyVideo";
import NearByInfo from "../../components/propertySingle/NearByInfo";
import NearBySimilars from "../../components/propertySingle/NearBySimilars";
import { useDispatch } from "react-redux";
import { setHeaderClass } from "../../redux/layoutSlice";
import { useEffect, useState } from "react";
import ReportProperty from "../../components/propertySingle/ReportProperty";
import { countPropertyView, propertyDetailsApi } from "../../services/propertyService";
import { toast } from "react-toastify";
import LoaderMu from "../../components/common/LoaderMu";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ReactGA4 from "react-ga4"
import FloorPlans from "../../components/propertySingle/FloorPlans";

export const Propertysingle = () => {

  const { id } = useParams()

  const { i18n } = useTranslation()

  const [propertyDetails, setPropertyDetails] = useState({})
  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch()
  // SET HEADER CLASS IN REDUX
  useEffect(() => {
    dispatch(setHeaderClass({ className: "container" }))
  }, [])

  // CALL API TO GET PROPERTY DETAILS
  const fetchPropertyDetails = () => {
    setLoading(true)
    propertyDetailsApi(id).then((response) => {
      setLoading(false)
      setPropertyDetails(response)
    }).catch((error) => {
      setLoading(false)
      toast.error(
        error?.response?.data?.error_description
          ? error?.response?.data?.error_description
          : error?.response?.data?.message ?? error?.message
      );
    })
  }

  const propertyViewCount = () => {
    countPropertyView(id).catch((error) => {
      // CONSOLE ERROR HERE
    })
  }

  // FETCH PROPERTY DETAILS
  useEffect(() => {
    fetchPropertyDetails()
  }, [id, i18n?.language])

  // PROPERTY VIEW COUNT API
  useEffect(() => {
    propertyViewCount()
  }, [])

  useEffect(() => {
    if (propertyDetails?.title) {
      ReactGA4.event({
        category: "property_detail",
        action: "visit",
        item: Date.now(),
        title: propertyDetails?.title,
        description: "This property has been visited on web"
      });
    }
  }, [propertyDetails])

  return (
    <div className="body_content">
      <LoaderMu loading={loading} />
      {/* Property All Lists */}
      <section className="pt60 pb90 bgc-f7">
        <div className="container">
          {/* DONE */}
          <HeaderInfo propertyData={propertyDetails} />
          <PropertyImageSection propertyImages={propertyDetails?.propertyImages} />
          <div className="row wrap wow fadeInUp" data-wow-delay="500ms">
            <div className="col-lg-8">
              {/* PROPERTY OVERVIEW */}
              <PropertyOverview propertyData={propertyDetails} />
              {/* PROPERTY DESCRIPTION */}
              <PropertyDescription propertyData={propertyDetails} />
              {/* TOUR 3D  DO IT DYNAMIC LATER*/}
              {
                propertyDetails?.virtualTourUrl && <Tour3d virtualTourUrl={propertyDetails?.virtualTourUrl} />
              }
              {/* PROPERTY ADDRESS */}
              <PopertyAddress propertyData={propertyDetails} />
              {/* FEATURE AMENITIES SHOULD DISPLAY ONLY IF AMENITIES AVAILABLE IN DATA */}
              {
                propertyDetails && propertyDetails?.amenities && propertyDetails?.amenities?.length > 0 ?
                  <FeatureAmenitites amenities={propertyDetails?.amenities} /> : ""
              }
              {/* FLOOR PLANS */}
              {/* ONLY IF FLOOR PLANS AVAILABLE */}
              {
                propertyDetails?.propertyFloorImages && propertyDetails?.propertyFloorImages?.length > 0 ?
                  <FloorPlans floorData={propertyDetails?.propertyFloorImages} /> : ""
              }
              {/* VIDEO SECTION SHOULD DISPLAY ONLY IF VIDEO AVAILABLE IN DATA */}
              {
                propertyDetails?.videoSource || propertyDetails?.videoSourceFull?
                  <PropertyVideo propertyData={propertyDetails} /> : ""
              }
              {/* NEAR BY PROPERTIES */}
              {
                propertyDetails?.googlePlaceNearbysearchDataList && propertyDetails?.googlePlaceNearbysearchDataList?.length > 0 ?
                  <NearByInfo nearByInfo={propertyDetails?.googlePlaceNearbysearchDataList} /> : ""
              }
            </div>
            {/* RIGHT SECTION OF PAGE*/}
            <div className="col-lg-4">
              <div className="column">
                {/* AGENT INFO */}
                <AgentpersonalInfo agentInfo={propertyDetails?.agent} agentId={propertyDetails?.agentId} agencyId={propertyDetails?.agencyId} agencyInfo={propertyDetails?.agency} />
                <TourScheduleForm propertyId={propertyDetails?.id} propertyTitle={propertyDetails?.title} />
                <ReportProperty />
              </div>
            </div>
          </div>
          {
            propertyDetails?.id ? <NearBySimilars propertyId={propertyDetails?.id} /> : ""
          }
        </div>
      </section>
      {/* Our Footer */}
      <Footer />
    </div>
  );
};
