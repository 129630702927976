import React, { useEffect, useState } from 'react'
import { useDispatch } from "react-redux";
import { setHeaderClass } from "../../redux/layoutSlice";
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getPageContent } from '../../services/cmsPageService';
import { Footer } from '../../components/layouts/Footer/Footer';
import LoaderMu from '../../components/common/LoaderMu';
import { nameBasedRoutes } from '../../router/public';


export const PrivacyPolicy = () => {
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)

  const [pageContent, setPageContent] = useState({})

  useEffect(() => {
    dispatch(setHeaderClass({ className: "container" }))
  }, [])

  // FETCH CMS DATA
  const fetchCmsData = () => {
    setLoading(true)
    getPageContent("privacy-policy").then(response => {
      setLoading(false)
      setPageContent(response)
    }).catch((error) => {
      setLoading(false)
      toast.error(
        error?.response?.data?.error_description
          ? error?.response?.data?.error_description
          : error?.response?.data?.message
      );
    })
  }

  // FETCH CMS DATA
  useEffect(() => {
    fetchCmsData()
  }, [])

  return (
    <div className="body_content">
    <LoaderMu loading={loading}/>
      {/* Our Contact With Map */}
      <section className="breadcumb-section pt60 pb30">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="breadcumb-style1">
                <h2 className="title">{pageContent?.title}</h2>
                <div className="breadcumb-list">
                  <Link to={nameBasedRoutes?.home?.path}>Home</Link>
                  <Link to={nameBasedRoutes?.privacyPolicy?.path}>{pageContent?.title}</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-0 pb60">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 mb30">
              <div className="static-data text-justify fs-16 mt-2" dangerouslySetInnerHTML={{ __html: pageContent?.content }} />
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
