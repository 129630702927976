import React, { useEffect, useRef } from 'react'
function MapMarker({ hideLocation, propertyTypeInfo, coordinateData }) {

    const mapElementRef = useRef(null);

    const mapCenter = { lat: coordinateData?.y ? parseFloat(coordinateData?.y) : 37.7749, lng: coordinateData?.x ? parseFloat(coordinateData?.x) : -122.4194 }

    // // Function to calculate the position at the top of the circle
    // function getTopPosition(center, radius) {
    //     // Convert radius from meters to degrees
    //     const radiusInDegrees = radius / 111320; // 111320 meters per degree (approx.)

    //     return {
    //       lat: center.lat() + radiusInDegrees,
    //       lng: center.lng()
    //     };
    //   }

    function getRandomPosition(center, radius) {
        const radiusInDegrees = radius / 111320;
        const angle = Math.random() * 2 * Math.PI;
        const distance = Math.random() * radiusInDegrees;
        
        return {
          lat: center.lat() + distance * Math.cos(angle),
          lng: center.lng() + distance * Math.sin(angle)
        };
      }

    useEffect(() => {
        const initMap = async () => {
            try {
                // Request needed libraries.
                const { Map } = await window.google.maps.importLibrary('maps');
                const { AdvancedMarkerElement } = await window.google.maps.importLibrary('marker');

                const map = new Map(mapElementRef.current, {
                    center: { lat: coordinateData?.y ? parseFloat(coordinateData?.y) : 37.7749, lng: coordinateData?.x ? parseFloat(coordinateData?.x) : -122.4194 },
                    zoom: 15,
                    mapId: '4504f8b37365c3d0',
                });

                // NOT DELIVERABLE
                
                // IF EXACT LOCATION HIDE THEN SHOW CIRCLE IN 5 KM RADIUS
                if (hideLocation === true) {
                     // Draw a circle  
                    const circle = new window.google.maps.Circle({
                        strokeColor: "transparent",
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                        fillColor: "#149cc5",
                        fillOpacity: 0.2,
                        map: map,
                        center: mapCenter, // Centered around Jordan
                        radius: 500, // 500 meters
                    });
                    const topPosition = getRandomPosition(circle.getCenter(), circle.getRadius());
                    const beachFlagImg = document.createElement('span');
                    beachFlagImg.className = `icon ${propertyTypeInfo?.iconClassName ?? 'flaticon-home'} map-icon`
                    const marker = new AdvancedMarkerElement({
                        map,
                        content: beachFlagImg,
                        position: topPosition,
                    });

                } else {
                    // IF COORDINATE DATA AVAILABLE THEN ONLY SHOW MARKER
                    if (coordinateData?.y && coordinateData?.x) {
                        const beachFlagImg = document.createElement('span');
                        beachFlagImg.className = `icon ${propertyTypeInfo?.iconClassName ?? 'flaticon-home'} map-icon`
                        const marker = new AdvancedMarkerElement({
                            map,
                            content: beachFlagImg,
                            position: { lat: coordinateData?.y ? parseFloat(coordinateData?.y) : 37.7749, lng: coordinateData?.x ? parseFloat(coordinateData?.x) : -122.4194 },
                        });

                    }
                }

                // IF COORDINATE DATA AVAILABLE THEN ONLY SHOW MARKER
                // if (coordinateData?.y && coordinateData?.x) {
                //     const beachFlagImg = document.createElement('span');
                //     beachFlagImg.className = `icon ${propertyTypeInfo?.iconClassName ?? 'flaticon-home'} map-icon`
                //     const marker = new AdvancedMarkerElement({
                //         map,
                //         content: beachFlagImg,
                //         position: { lat: coordinateData?.y ? parseFloat(coordinateData?.y) : 37.7749, lng: coordinateData?.x ? parseFloat(coordinateData?.x) : -122.4194 },
                //     });

                // }
            } catch (error) {
                console.error('Error initializing map:', error);
            }
        };

        if (window.google) {
            initMap();
        }

    }, [coordinateData]);
    return (
        <div ref={mapElementRef} style={{ width: '100%', height: '350px' }} />
    )
}

export default MapMarker
