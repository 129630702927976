import React from 'react'
import RealEstateAgency from "../../assets/images/about/cta-building-1.png";
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// CLICK TO AGENCY SECTION

const LoginAgency = () => {

  const { t } = useTranslation()

  const navigate = useNavigate()

  return (
    <section className="our-cta pt150 pt20-md p-0">
      <div className="cta-banner bgc-thm-light mx-auto maxw1600 pt90 pt60-md pb90 pb60-md bdrs12 position-relative mx20-lg px20-md">
        <div className="img-box-5 d-none d-md-block">
          <img
            className="img-1 bounce-x"
            src="images/about/element-15.png"
            alt=""
          />
        </div>
        <div className="img-box-8 d-none d-md-block">
          <img
            className="img-1 bounce-y"
            src="images/about/element-14.png"
            alt=""
          />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-xl-6 wow fadeInLeft">
              <div className="cta-style3">
                <h2 className="cta-title mb5 mb25-md">
                  <span className="text-orange"> {t('A REAL ESTATE AGENCY?')}</span>{" "}
                  {t('START LISTING')} 
                </h2>
                <p className="cta-text d-none d-md-block">
                  {t("IT'S SIMPLE AND EASY, CLICK BELOW AND FOLLOW THE INSTRUCTIONS.")}
                </p>
                <Link to={process.env.REACT_APP_AGENCY_WEB_URL}
                  target="_blank" className="ud-btn btn-dark">
                  {t("AGENCIES LOGIN")} <i className="fal fa-arrow-right-long" />
                </Link>
              </div>
            </div>
            <div
              className="col-lg-5 col-xl-4 offset-xl-2 d-none d-lg-block wow fadeIn"
              data-wow-delay="300ms"
            >
              <div className="cta-img">
                <img src={RealEstateAgency} alt="Real Estate Agency" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default LoginAgency
