import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setHeaderClass } from '../../redux/layoutSlice'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { propertyDetailsApi } from '../../services/propertyService'
import { toast } from 'react-toastify'
import { nameBasedRoutes } from '../../router/public'
import LoaderMu from '../../components/common/LoaderMu'
import Cookies from 'universal-cookie'
import ReactGA4 from "react-ga4"

const Compare = () => {

	const { t } = useTranslation()

	const {i18n} = useTranslation()

	const cookies = new Cookies()

	const userLanguage = i18n?.language ?? cookies.get('userLangKey') ?? 'en';

	const dispatch = useDispatch()

	const location = useLocation()

	const params = new URLSearchParams(location?.search)

	const paramsObj = Object.fromEntries(new URLSearchParams(params));

	const [loading, setLoading] = useState(true)

	const [propertyDetails, setPropertyDetails] = useState([])

	const [compareKeys, setCompareKeys] = useState({})

	const navigate = useNavigate()

	// ARRAY OF COMPARE PROPERTIES FROM
	const compareProp = useSelector(state => state?.compareProps ?? [])

	useEffect(() => {
		dispatch(setHeaderClass({ className: "container" }))
	}, [])

	// FETCH PROPERTY DETAILS API
	const fetchPropertyDetails = async (pid) => {
		try {
			const { id, title, propertyImages, isFeatured, propertyCategory, propertyDetail, propertyType } = await propertyDetailsApi(pid);
			return { id, title, propertyImages, isFeatured, propertyCategory, propertyDetail, propertyType };
		} catch (error) {
			const errorMessage =
				error?.response?.data?.error_description ||
				error?.response?.data?.message ||
				error?.message;
			throw new Error(errorMessage);
		}
	};

	useEffect(() => {
		setPropertyDetails([]);

		const fetchAndSetPropertyDetails = async (propIds) => {
			const uniquePropIds = [...new Set(propIds)]; // Remove duplicates
			setLoading(true);
			try {
				const responses = await Promise.all(uniquePropIds.map(fetchPropertyDetails));
				setPropertyDetails(responses);
			} catch (error) {
				toast.error(error?.message);
			} finally {
				setLoading(false);
			}
		};

		if (compareProp?.length > 0) {
			navigate(`${nameBasedRoutes?.compareProperties?.path}?properties=${compareProp}`);
			fetchAndSetPropertyDetails(compareProp);
		} else if (paramsObj && paramsObj?.properties) {
			const idArray = paramsObj.properties.split(',').map(value => parseInt(value));
			fetchAndSetPropertyDetails(idArray);
		}
	}, []);

	useEffect(() => {
		if (propertyDetails && propertyDetails?.length > 0) {
			const keysArr = propertyDetails[0]?.propertyType?.propertyDetail?.map((element) => ({
				field_level: element?.field_level,
				field_name: element?.field_name,
				field_type: element?.field_type
			}))
			setCompareKeys(keysArr)
		}
		// if(propertyDetails?.length > 0 && propertyDetails[0]?.propertyDetail) {
		//     const keysArr = Object.keys(propertyDetails[0]?.propertyDetail).filter(key => !excludedKeys.includes(key));
		// 		setCompareKeys(keysArr)
		// }
	}, [propertyDetails])

	useEffect(()=>{
		if(compareProp && compareProp?.length > 0){
			ReactGA4.event({
				category: "compare_property",
				action: "compare",
				id: Date.now(),
				item: "Comapare Properties",
				description: JSON.stringify(compareProp)
			  })
		}
	},[])

	return (
		// <div className="container">
		// 	<div className="row">
		// 		<div className="col-12 overflow-auto">
		// 			<div className="compare-row w-100 bg-danger ">
		// 				<div className="compare-item">
		// 					2
		// 				</div>
		// 				<div className="compare-item">
		// 					2
		// 				</div>
		// 				<div className="compare-item">
		// 					3
		// 				</div>
		// 				<div className="compare-item">
		// 					4
		// 				</div>
		// 				<div className="compare-item">
		// 					5
		// 				</div>

		// 			</div>
		// 		</div>
		// 	</div>
		// </div>
		<div className="body_content">
			<LoaderMu loading={loading} />
			<section className="breadcumb-section bgc-f7 pt30 pb30">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="breadcumb-style1">
								<h2 className="title"> {t('COMPARE PROPERTIES')}</h2>
								<div className="breadcumb-list">
									<Link to="/">  {t("HOME")}</Link>
									<Link to="/compare-properties">{t('COMPARE PROPERTIES')}</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="pt-0 bgc-f7">
			<div className="container overflow-auto">
			
				
					<div className="compare-row">
						<div className='compare-title '></div>
						{
							propertyDetails?.map((property) => {
								let mainImage = property?.propertyImages?.filter(image => image?.mainImage === true)
								return <div className='compare-item'>
									<div className="compare-thumb ">
										<img src={mainImage[0]?.thumbnailImageName} alt="mainImage" />
									</div>
									<div>
										<Link to={`${nameBasedRoutes?.propertyDetails?.path}/${property?.id}`} className='fs-18 fw-600 text-decoration-underline mt10'>{property?.title}</Link>
									</div>
								</div>
							})
						}
					</div>
					{
						compareKeys && compareKeys?.length > 0 ? compareKeys?.map((key, index) => {
							return <div key={index + 1} className={'compare-row'}>
								<div className={`compare-title ${index % 2 === 0 ? 'bgc-thm-light ' : ""}`}><p className='mb-0 fw-bold fs-16'>{key?.field_level[userLanguage]}</p></div>
								{
									propertyDetails && propertyDetails?.length > 0 ? propertyDetails?.map((property, propIndex) => {
										if (key?.field_type === "Boolean") {
											return <div key={propIndex + 1} className={`compare-item ${index % 2 === 0 ? 'bgc-thm-light ' : ""}`}> <p className='mb-0'>
												{property.propertyDetail && property.propertyDetail[key?.field_name] ? t("YES") : <span className='text-danger'>N/A</span>}
											</p></div>
										} else {
											return <div key={propIndex + 1} className={`compare-item ${index % 2 === 0 ? 'bgc-thm-light ' : ""}`}> <p className='mb-0'>
												{property.propertyDetail && property.propertyDetail[key?.field_name] ? property.propertyDetail[key?.field_name] : <span className='text-danger'>N/A</span>}
											</p></div>
										}

									}) : ""
								}
							</div>
						}) : ""
					}
				</div>
			</section >
		</div >
	)
}

export default Compare