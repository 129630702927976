import React, { useEffect, useState } from "react";
import logoImg2 from "../../../assets/images/Amaken-logo2.svg";
import logoAr2 from "../../../assets/images/Amaken-logo2-ar.svg"
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaInstagram } from "react-icons/fa6";
import { popularSearchListApi } from "../../../services/userService";
import { toast } from "react-toastify";
import { nameBasedRoutes } from "../../../router/public";
import { FaApple, FaGooglePlay } from "react-icons/fa";
import { homeGovernorateList } from "../../../services/homePageServices";
import { constructUrl } from "../../../services/propertyService";
import { Skeleton } from "antd";
import Cookies from "universal-cookie";
export const Footer = () => {

  const { t } = useTranslation()
  const { i18n } = useTranslation()
  const cookies = new Cookies()
  //GET USER PREFERRED LANGUAGE
  const userLanguage = i18n?.language ?? cookies.get('userLangKey') ?? 'en';

  const [popularSearch, setPopularSearch] = useState([])
  const [governorateList, setGovernorateList] = useState([])
  const [loading, setLoading] = useState(true)

  const navigatePath = nameBasedRoutes?.propertyList?.path


  // GET POPULAR SEARCH LIST
  const fetchPopularSearchList = () => {
    setLoading(true)
    popularSearchListApi().then((response) => {
      const searchList = response
      let SearchTitle = searchList?.map((element) => {
        let title = "";
        let navigationUrl = navigatePath
        // CATEGORY . OFFERING TYPE . PROPERTY TYPE
        if (element?.propertyCategory && element?.propertyTypeList && element?.propertyTypeList?.length > 0 && element?.propertyOfferingType) {
          title =
            `${element?.propertyCategory} 
           ${element?.propertyTypeList[0]?.title} For
           ${element?.propertyOfferingType}
           `
          navigationUrl += `?propertyOfferingType=${element?.propertyOfferingType}&propertyCategory=${element?.propertyCategory}&propertyTypeIdList=${element?.propertyTypeList[0]?.id}`
        }   // CATEGORY . PROPERTY TYPE
        else if (element?.propertyCategory && element?.propertyTypeList && element?.propertyTypeList?.length > 0) {
          title = `${element?.propertyCategory} 
          ${element?.propertyTypeList[0]?.title}`
          navigationUrl += `?propertyCategory=${element?.propertyCategory}&propertyTypeIdList=${element?.propertyTypeList[0]?.id}`
        }  //  OFFERING TYPE . PROPERTY TYPE 
        else if (element?.propertyOfferingType && element?.propertyTypeList && element?.propertyTypeList?.length > 0) {
          title = `${element?.propertyTypeList[0]?.title} For ${element?.propertyOfferingType} `
          navigationUrl += `?propertyOfferingType=${element?.propertyOfferingType}&propertyTypeIdList=${element?.propertyTypeList[0]?.id}`
        }
        // CATEGORY . OFFERING TYPE 
        else if (element?.propertyCategory && element?.propertyOfferingType) {
          title = `${element?.propertyCategory} Properties for ${element?.propertyOfferingType}`
          navigationUrl += `?propertyOfferingType=${element?.propertyOfferingType}&propertyCategory=${element?.propertyCategory}`
        }
        // OFFERING TYPE . GOVERNORATE ID
        else if (element?.propertyOfferingType && element?.governorate) {
          title = `Properties for ${element?.propertyOfferingType} in ${element?.governorate?.capital}`
          navigationUrl += `?propertyOfferingType=${element?.propertyOfferingType}&governorateId=${element?.governorate?.id}`
        }  // GOVERNORATE ID
        else if (element?.governorate) {
          title += `Properties in ${element?.governorate?.capital}`
          navigationUrl += `?governorateId=${element?.governorate?.id}`
        }
        // else{
        //   title = `Properties for ${element?.propertyOfferingType}`
        // }
        return { title: title, path: navigationUrl }

      }).filter(element => element?.title !== "");

      const finalList = Array.from(new Set(SearchTitle));

      setPopularSearch(finalList)

      setLoading(false)
      // setPropertiesList(response?.data)
    }).catch((error) => {
      setLoading(false)
      toast.error(
        error?.response?.data?.error_description
          ? error?.response?.data?.error_description
          : error?.response?.data?.message ?? error?.message
      );
    })
  }

  // FETCH PROPERTIES BY CITIES
  const propertiesByCities = () => {
    setLoading(true)
    homeGovernorateList().then((response) => {
      setLoading(false)
      setGovernorateList(response)
    }).catch((error) => {
      setLoading(false)
      toast.error(
        error?.response?.data?.error_description
          ? error?.response?.data?.error_description
          : error?.response?.data?.message ?? error?.message
      );
    })
  }
  useEffect(() => {
    fetchPopularSearchList() // POPULAR SEARCH LIST API
    propertiesByCities() // PROPERTIES BY CITIES API
  }, [i18n?.language])

  const navigate = useNavigate()
  //HANDLE PROPERTIES FILTER WITH GOVERNORATE AND REDIRECT TO PROPERTY LISTING
  const handleRedirectProperties = (event, governorateId) => {
    event.preventDefault()
    const param = { key: "governorateId", value: governorateId }
    const navigateURl = constructUrl(navigatePath, param)
    navigate(navigateURl)
  }

  return (
    <section className="footer-style1 at-home4 pt60 pb-0">
      <div className="container">
        <div className="row">
          <div className="col-sm-6 col-lg-3">
            <div className="footer-widget light-style mb-4 mb-lg-5">
              {
                userLanguage === "ar" ?
                  <Link className="footer-logo" to="/">
                    <img className="mb40" src={logoAr2} alt="footer logo" />
                  </Link> : <Link className="footer-logo" to="/">
                    <img className="mb40" src={logoImg2} alt="footer logo" />
                  </Link>
              }
              <div className="contact-info mb25">
                <p className="text mb5">{t('NEED SUPPORT?')}</p>
                {/* <h6 className="info-phone">
                  <a href={`tel:+96261234567`}>+(962) 6 123 4567</a>
                </h6> */}
                <h6 className="info-mail">
                  <Link to="mailto:help@amakenplus.com">help@amakenplus.com</Link>
                </h6>
              </div>
              <div className="footer-widget mb-4 mb-lg-5">
                <div className="light-style mb-4">
                  <h6 className="mb20">{t('DISCOVER JORDAN CITIES')}</h6>
                  <div className="ps-0">
                    {
                      loading === true ?
                        <Skeleton
                          paragraph={{
                            rows: 3,
                            width: "40%"
                          }}
                          title={false}
                        />
                        :
                        governorateList && governorateList?.length > 0 ?
                          governorateList.slice(0, 12)?.map((element, index) => {
                            return <span key={index + 1} ><Link className="px5" to="#" onClick={(event) => handleRedirectProperties(event, element?.id)}>
                              {element?.capital}
                            </Link>
                              {index + 1 !== governorateList?.length ? " / " : ""}
                            </span>
                          }) : ""
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-3">
            <div className="link-style1 light-style mb30">
              <h6 className="mb25">{t('POPULAR SEARCH')}</h6>
              <div className="link-list">
                {
                  loading === true ?
                    <Skeleton
                      paragraph={{ rows: 7, width: "100%" }} title={false}
                    />
                    :
                    popularSearch && popularSearch?.length > 0 ?
                      popularSearch.slice(0, 6)?.map((element, index) => {
                        return <Link key={index + 1} to={element?.path} className="text-capitalize d-block">{element?.title ? element?.title.toLowerCase() : ""}</Link>
                      }) : ""
                }
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-3">
            <div className="footer-widget mb-4 mb-lg-5 ps-0 ps-lg-5">
              <div className="link-style1 light-style mb-3">
                <h6 className="mb25">{t('QUICK LINKS')}</h6>
                <ul className="ps-0">
                  <li>
                    <Link to={nameBasedRoutes?.about?.path}>{t('ABOUT AMAKEN')}</Link>
                  </li>
                  <li>
                    <Link to={nameBasedRoutes?.termsConditions?.path}>{t('TERMS & CONDITION')}</Link>
                  </li>
                  <li>
                    <Link to={nameBasedRoutes?.privacyPolicy?.path}>{t('PRIVACY POLICY')}</Link>
                  </li>
                  <li>
                    <Link to={nameBasedRoutes?.faq?.path}>{t('FAQS')}</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-3">
            <div className="footer-widget mb-4 mb-lg-5">
              <div className="app-widget">
                <h5 className="title mb10">{t('DOWNLOAD AMAKEN APP')}</h5>
                <div className="row">
                  <div className="col-auto">
                    <a  target="_blank" href="https://apps.apple.com/app/amaken/id6476543362">
                      <div className="app-info light-style d-flex align-items-center mb10">
                        <div className="flex-shrink-0">
                          <FaApple className="text-white fz30" />
                          {/* <i className="fab fa-apple fz30 text-white" /> */}
                        </div>
                        <div className="flex-grow-1 ml20">
                          <p className="app-text fz13 mb0">{t('DOWNLOAD ON THE')}</p>
                          <h6 className="app-title text-white fz14">
                            {t('APPLE STORE')}
                          </h6>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="col-auto">
                    <a href="#">
                      <div className="app-info light-style d-flex align-items-center mb10">
                        <div className="flex-shrink-0">
                          <FaGooglePlay className="fz30 text-white" />
                        </div>
                        <div className="flex-grow-1 ml20">
                          <p className="app-text fz13 mb0">{t('GET IN ON')}</p>
                          <h6 className="app-title text-white fz14">
                            {t('GOOGLE PLAY')}
                          </h6>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container gray-bdrt1 py-4">
        <div className="row justify-content-between">
          <div className="col-auto">
            <div className="text-start text-lg-start">
              <p className="copyright-text ff-heading mb-0">
                © {t('AMAKEN - ALL RIGHTS RESERVED')}
              </p>
            </div>
          </div>
          <div className="col-auto">
            <div className="social-widget text-center text-sm-end">
              <div className="social-style1 light-style">
                <Link className="me-2 fw600 fz15" to="">
                  {t('FOLLOW US')}
                </Link>
                <a target="_blank" href="https://www.facebook.com/profile.php?id=61561573122446">
                  <i className="list-inline-item me-1" ><FaFacebookF /></i>
                </a>
                {/* <Link to="#">
                  <i className="list-inline-item me-1" ><FaTwitter /></i>
                </Link> */}
                <a target ="_blank" href="https://www.instagram.com/amakenplus.jordan/?igsh=bmFvOW9wYjA3YmM0">
                  <i className="list-inline-item me-1" ><FaInstagram /></i>
                </a>
                <a target="_blank" href = "https://www.linkedin.com/company/amakenplusjo">
                  <i className="list-inline-item me-1" ><FaLinkedinIn /></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
