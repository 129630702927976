import { Link, useLocation, useNavigate } from "react-router-dom"
import PropertiesImg from "../../assets/images/listings/listing-single-1.jpg"
import { useTranslation } from "react-i18next"
import { useEffect, useState } from "react"
import { favoritesListApi } from "../../services/userService"
import { toast } from "react-toastify"
import PropertyCard from "../../components/Property/PropertyCard"
import { Pagination } from "@mui/material"
import { Empty, Skeleton } from "antd"
import { setHeaderClass } from "../../redux/layoutSlice"
import { useDispatch } from "react-redux"
import { nameBasedProtectedRoutes } from "../../router/protected"

export const Favorites = () => {
  const { t } = useTranslation()

  // FOR PAGINATION USING URL

  const location = useLocation()

  const params = new URLSearchParams(location.search)

  const currentPage = params.get("page") ?? 1

  const navigate = useNavigate()



  const dispatch = useDispatch()

  const [loading, setLoading] = useState(true)

  const [propertiesList, setPropertiesList] = useState([])
  // PAGINATION STATS
  const [pageSize, setPageSize] = useState(9)
  const [totalRowCount, setTotalRowCount] = useState(0)

  // FETCH PROPERTIES FOR YOU LIST
  const fetchPropertiesForYou = () => {
    setLoading(true)
    const params = {
      size: pageSize,
      page: currentPage - 1
    }
    favoritesListApi(params).then((response) => {
      setLoading(false)
      setPropertiesList(response?.data)
      setTotalRowCount(response?.headers["x-total-count"])
    }).catch((error) => {
      setLoading(false)
      toast.error(
        error?.response?.data?.error_description
          ? error?.response?.data?.error_description
          : error?.response?.data?.message ?? error?.message
      );
    })
  }
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    })
  }, [])

  useEffect(() => {
    fetchPropertiesForYou()
  }, [currentPage])


  // HANDLE CHANGE PAGE
  const handlePageChange = (event, value) => {
    navigate(`/${nameBasedProtectedRoutes?.favorites?.path}?page=${value}`)
  }

  useEffect(() => {
    dispatch(setHeaderClass({ className: "container" }))
  }, [])

  return (
    <div className="body_content">
      <section className="breadcumb-section bgc-f7 pt30 pb30">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="breadcumb-style1">
                <h2 className="title"> {t('MY FAVORITES')}</h2>
                <div className="breadcumb-list">
                  <Link to="/">  {t("HOME")}</Link>
                  <Link to={nameBasedProtectedRoutes?.favorites?.path}>{t('MY FAVORITES')}</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-0 bgc-f7">
        <div className="container">
          <div className="row">
            {
              loading === true ?
                <>
                  {[...Array(6)].map((element, index) => {
                    return <div key={index + 1} className="col-sm-6 col-xl-4 my-2">
                      <Skeleton.Button active={true} size={"100%"} shape={"square"} block={true} style={{ height: "400px" }} />
                    </div>
                  })
                  }
                </> :

                propertiesList && propertiesList?.length > 0 ?
                  propertiesList?.map((property, index) => {
                    return <div key={index + 1} className="col-sm-6 col-xl-4">
                      <PropertyCard propertyDetails={property} isFavorite={true} setPropertiesList={setPropertiesList} /></div>
                  })
                  : <Empty description={t("NO PROPERTIES FOUND")} />
            }
          </div>
          {/* PAGINATION */}
          {
            (!loading && propertiesList && propertiesList?.length) ?
              <div className="row">
                <div className="col-12">
                  <div className="mbp_pagination text-center mt30 dataTable_pagination">
                    <Pagination count={Math.ceil(totalRowCount / pageSize)} siblingCount={1} page={parseInt(currentPage)} className="d-inline-block" size="large" onChange={(event, value) => handlePageChange(event, value)} />
                  </div>
                </div>
              </div> : ""
          }
        </div>
      </section>
    </div>
  )
}
