import { Profileuser } from "../pages/Profileuser";

import { Layout } from '../components/layouts/Layout';
import { Home } from '../pages/Home';
import { Favorites } from "../pages/Favorites";
import { useSelector } from "react-redux";


/**
 * Routes which can only be accessed after authentication
 * @type {{}}
 */


export const protectedRoutes = [
    {
        path: '/',
        element: <Layout />,
        children: [
            { path: '/', element: <Home /> },
            { path: '/home', element: <Home /> },
            { path: '/favorites', element: <Favorites /> },
            // { path: '/profile-user', element: <Profileuser /> },
        ],
    }
];

// Named Routes

export const nameBasedProtectedRoutes =
{
    userHome: {
        name: "home",
        path: `/home`,
    },
    userDashboard: {
        name: "Dashboard",
        path: `/`,
    },
    userProfile: {
        name: "Profile",
        path: `/profile-user`
    },
    favorites: {
        name: "favorites",
        path: `/favorites`
    }
} 
