import { useDispatch } from "react-redux";
import AboutImg from "../../assets/images/about/about-page-banner.jpg";
import { Footer } from "../../components/layouts/Footer/Footer";
import { setHeaderClass } from "../../redux/layoutSlice";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { nameBasedRoutes } from "../../router/public";

export const About = () => {

  const { t } = useTranslation()
  const dispatch = useDispatch()
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    })
    dispatch(setHeaderClass({ className: "container" }))
  }, [])
  return (
    <div className="body_content">
      {/* UI Elements Sections */}
      <section className="breadcumb-section pt60 pb30">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="breadcumb-style1">
                <h2 className="title">{t("ABOUT AMAKEN")}</h2>
                <div className="breadcumb-list">
                  <Link to={nameBasedRoutes?.home?.path}>{t("HOME")}</Link>
                  <Link to={nameBasedRoutes?.about?.path}>{t("ABOUT AMAKEN")}</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Our About Area */}
      <section className="our-about pt60 pb60">
        <div className="container">
          <div className="row wow fadeInUp" data-wow-delay="300ms">
            <div className="col-lg-6">
              <h2>
                {t("WE'RE ON A MISSION TO CHANGE")}{" "}
                <br className="d-none d-lg-block" /> {t("THE VIEW OF ONLINE REAL ESTATE MARKETPLACE FIELD IN JORDAN.")}
              </h2>
            </div>
            <div className="col-lg-6">
              <p className="text mb25 text-justify">
                {t("ABOUT TEXT P1")}
              </p>
              <p className="text mb55 text-justify">
                {t("ABOUT TEXT P2")}
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* Funfact */}
      <section className="pt-0 pb60">
        <div className="container">
          <div
            className="row justify-content-center wow fadeInUp"
            data-wow-delay="300ms"
          >
            <div className="col-md-4">
              <div className="funfact_one text-center">
                <div className="details">
                  <p className="text mb-0 text-gray">{t("MORE THAN")}</p>
                  <ul className="ps-0 mb-0 d-flex justify-content-center">
                    <li>
                      <div className="timer">8</div>
                    </li>
                  </ul>
                  <p className="text mb-0 fw600 fz16">{t("TRUSTED AGENCIES")}</p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="funfact_one text-center">
                <div className="details">
                  <p className="text mb-0 text-gray">{t("MORE THAN")}</p>
                  <ul className="ps-0 mb-0 d-flex justify-content-center">
                    <li>
                      <div className="timer">2,000</div>
                    </li>
                  </ul>
                  <p className="text mb-0 fw600 fz16">{t("AMAZING PROPERTY")}</p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="funfact_one text-center">
                <div className="details">
                  <p className="text mb-0 text-gray">{t("AND")}</p>
                  <ul className="ps-0 mb-0 d-flex justify-content-center">
                    <li>
                      <div className="timer">{t("THOUSANDS")}</div>
                    </li>
                  </ul>
                  <p className="text mb-0 fw600 fz16">{t("OF HAPPY CUSTOMERS")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* About Banner */}
      <section className="our-about pt-0">
        <div className="container">
          <div className="row wow fadeInUp" data-wow-delay="300ms">
            <div className="col-lg-12">
              <div className="about-page-img">
                <img
                  className="w-100 bdrs24"
                  src={AboutImg}
                  alt="About Img"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};
