import React, { useEffect, useState } from 'react';
import { FaPencil } from 'react-icons/fa6';
import { RxCross2 } from "react-icons/rx";
import { nameBasedRoutes } from '../../router/public';
import { useTranslation } from 'react-i18next';
import mapMarker from "../../assets/images/resource/map-marker-2.png"
import mapMarker1 from "../../assets/images/resource/mapmarker.png"
import { toast } from 'react-toastify';
import { MarkerClusterer } from "@googlemaps/markerclusterer";

const MapSearchDemo = ({ areaSearchText, setPlaces, selectedPlaceId, setData, propertyList, setMapBounds }) => {

  const { t } = useTranslation()

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 500);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 575.98);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [map, setMap] = useState(null);

  // FOR AREA SEARCH 

  const [selectedPlace, setSelectedPlace] = useState(null)
  const [previousMarker, setPreviousMarker] = useState(null);


  const [prevMarkerList, setPrevMarkerList] = useState(null)
  const [drawSearchApplied, setDrawSearchApplied] = useState(false)


  // FOR DRAWING 
  const [isDrawing, setIsDrawing] = useState(false)
  const [poly, setPoly] = useState(null);
  const [drawing, setDrawing] = useState(false);
  const [path, setPath] = useState([]);

  const [polylines, setPolylines] = useState([])

  // JORDAN CENTER POINT FOR DISPLAY MAP
  const JORDAN = { lat: 31.605903, lng: 36.330817 }
  // RESTRICT MAP TO JORDAN ONLY
  const JORDAN_BOUNDS = {
    north: 33.3755,
    south: 29.1851,
    west: 34.9594,
    east: 39.3012,
  };


// Debounce function definition
const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func(...args), delay);
  };
};
  // INITIALIZE MAP
  const initializeMap = (options) => {
    const mapOptions = {
      zoom: options?.zoom ?? 8,
      minZoom: 8,
      // center: { lat: -28.024, lng: 140.887 },
      rotateControl: false,
      center: options?.center ?? JORDAN,
      mapTypeId: window.google.maps.MapTypeId.ROADMAP,
      // restriction: {
      //   // latLngBounds: JORDAN_BOUNDS,
      //   strictBounds: false,
      // },
      mapTypeControl: false,
      streetViewControl: false,
      // mapId: "AIzaSyCsfNxQXgyadP6mMA-QfyckGYoMCbh8UNI",
      mapId: process.env.REACT_APP_GOOGLE_MAP_KEY
    };
    const newMap = new window.google.maps.Map(document.getElementById('map_canvas'), mapOptions);

    // newMap.addListener('bounds_changed', () => {
    //   const bounds = newMap.getBounds();
    //   if (bounds) {
    //     const ne = bounds.getNorthEast();
    //     const sw = bounds.getSouthWest();
    //     const newCoordinates = {
    //       northeast: { lat: ne.lat(), lng: ne.lng() },
    //       southwest: { lat: sw.lat(), lng: sw.lng() },
    //     };
    //     setMapBounds(newCoordinates)
    //     // console.log({ 'mapbound': newCoordinates });
    //     // console.log('zoomLevel : ' + newMap.getZoom())
    //   }
    // });

    const handleBoundsChanged = debounce(() => {
      const bounds = newMap.getBounds();
      if (bounds) {
        const ne = bounds.getNorthEast();
        const sw = bounds.getSouthWest();
        const newCoordinates = {
          northeast: { lat: ne.lat(), lng: ne.lng() },
          southwest: { lat: sw.lat(), lng: sw.lng() },
        };
        setMapBounds(newCoordinates);
        // You can add any additional logic here if needed
      }
    }, 300); // Adjust debounce delay as needed

    newMap.addListener('bounds_changed', handleBoundsChanged);

    setMap(newMap);
  };

  // INTIALIZE MAP
  useEffect(() => {
    // INITIALIZE GOOGLE MAP
    initializeMap();
  }, []);
 // Clean up event listeners on component unmount
 useEffect(() => {
  return () => {
    if (map) {
      window.google.maps.event.clearInstanceListeners(map);
    }
  };
}, [map]);
  // CALL ON AREA SEARCH
  useEffect(() => {
    const options = {
      bounds: JORDAN_BOUNDS,
      componentRestrictions: { country: "jo" }
    };
    if (areaSearchText && areaSearchText !== '' && map) {
      const service = new window.google.maps.places.AutocompleteService();
      service.getPlacePredictions({ input: areaSearchText, ...options }, (predictions, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK && predictions) {
          setPlaces(predictions);
        } else {
          setPlaces([]);
        }
      });
    } else {
      setPlaces([])
    }
  }, [areaSearchText])

  //  CALL ON AREA SELECT
  useEffect(() => {
    let featureLayer;
    if (map && selectedPlaceId && selectedPlaceId !== null) {
      const service = new window.google.maps.places.PlacesService(map);
      service.getDetails({ placeId: selectedPlaceId }, (place, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          setSelectedPlace(place);
          // IF PREVIOUS MARKER THEN REMOVE
          // if (previousMarker) {
          //   previousMarker.setMap(null)
          // }
          map.setCenter(place?.geometry?.location);
          map.setZoom(12.5)
          // const marker = new window.google.maps.Marker({
          //   map: map,
          //   position: place.geometry.location,
          //   icon: mapMarker
          // });
          // setPreviousMarker(marker)
        }
      });
    }
  }, [selectedPlaceId])

  // MAP MARKER INFO MODAL
  const MapCard = (property) => {
    return `<div class="infoBox map-info-wrapper" >
    <div class="d-flex">
      <div>
        <figure class="image">
          <img src=${property?.thumbnailImageName} alt="" class="map-thumb-img "/>
        </figure>
      </div>
      <div class ='map-info-card'>
        <h6>
          <a href=${process.env.REACT_APP_WEBSITE_WEB_URL + nameBasedRoutes?.propertyDetails?.path + "/" + property?.id} >${property?.title}<span class="icon icon-verified mt-2"></span></a>
        </h6>
        <ul class=" map-info-list">
          <li class='d-flex'
          ><span class="flaticon-maps me-1 mt-1 fs-6"></span> 
          
         <p class="mb-2">${property?.village?.village + ", " + property?.directorate?.directorate + ", " + property?.governorate?.governorate
      }</p>
          </li>
          ${property?.agent?.mobileNumber ? `<li><span class="flaticon-smartphone"></span>${property?.agent?.mobileNumber}</li>` : ""
      }
        </ul>
      </div>
    </div>
  </div>`
  }
  function getRandomPosition(center, radius) {

    const radiusInDegrees = radius / 111320;
    const angle = Math.random() * 2 * Math.PI;
    const distance = Math.random() * radiusInDegrees;

    return {
      lat: center.lat + distance * Math.cos(angle),
      lng: center.lng + distance * Math.sin(angle)
    };
  }
  // HANDLE MAP MARKERS AND CLUSTORS

  // useEffect(() => {
  //   if (map && propertyList && propertyList?.length > 0) {
  //     window.google.maps.importLibrary("marker").then(({ AdvancedMarkerElement }) => {
  //       if (!AdvancedMarkerElement) {
  //         // console.error("AdvancedMarkerElement is not available.");
  //         return;
  //       } else {

  //         let openInfoWindow = null

  //         // REMOVE PREVIOUS CLUSTOR MARKERS

  //         if (prevMarkerList) {
  //           prevMarkerList.setMap(null)
  //         }
  //         setPrevMarkerList(null)
  //         // ADJUST COORDINATES
  //         const adjustCoordinates = (center, index) => {

  //           const { lat, lng } = center
  //           const offset = 0.000004; // Very small offset value
  //           return {
  //             lat: lat + index * offset,
  //             lng: lng + index * offset
  //           };
  //         };

  //         // YET NOT DELIVERABLE

  //         let mapCenter = {}

  //         // propertyList?.filter((property) => property?.hideExactLocation === false)
  //         //MAP CLUSTOR MARKERS ARRAY
  //         const markers = propertyList?.map((property, index) => {
  //           mapCenter = { lat: property?.coordinateWgs84?.y ? parseFloat(property?.coordinateWgs84?.y) : 37.7749, lng: property?.coordinateWgs84?.x ? parseFloat(property?.coordinateWgs84?.x) : -122.4194 }

  //           if (property?.hideExactLocation === true) {
  //             // IF LOCATION HIDE IS TRUE THEN SHOW RANDOM LOCATION WITHIN 100 METER
  //             mapCenter = getRandomPosition(mapCenter, 100);
  //           }
  //           else { // ADJUST COORDINATES WITH MINOR SHIFT SO PROPERTIES WITH SAME COORDINATES CAN BE DISPLAY
  //             mapCenter = adjustCoordinates(mapCenter, index);
  //           }

  //           const infowindow = new window.google.maps.InfoWindow({
  //             content: MapCard(property),
  //             ariaLabel: "Uluru",
  //           });

  //           const imgE = document.createElement("img")
  //           imgE.src = mapMarker1

  //           const beachFlagImg = document.createElement('span');
  //           beachFlagImg.className = `icon ${property?.propertyType?.iconClassName ?? 'flaticon-home'} map-icon`
  //           const marker = new AdvancedMarkerElement({
  //             map: map,
  //             position: mapCenter,
  //             content: beachFlagImg ?? imgE
  //             // content: pinGlyph.element,

  //           });
  //           marker.addListener("click", () => {

  //             if (openInfoWindow) {
  //               openInfoWindow.close();
  //             }
  //             infowindow.open({
  //               anchor: marker,
  //               map,
  //             });
  //             openInfoWindow = infowindow
  //           });

  //           return marker;
  //         })
  //         const markerClusterer = new MarkerClusterer({ markers, map });
  //         setPrevMarkerList(markerClusterer);
  //       }
  //     })
  //   } else {
  //     if (prevMarkerList) {
  //       prevMarkerList.setMap(null);
  //     }
  //     setPrevMarkerList(null)
  //   }

  //   // Cleanup function
  //   return () => {
  //     if (prevMarkerList) {
  //       prevMarkerList.setMap(null);
  //     }
  //   };

  // }, [map, propertyList])

  useEffect(() => {
    if (map && propertyList && propertyList.length > 0) {
      window.google.maps.importLibrary("marker").then(({ AdvancedMarkerElement }) => {
        if (!AdvancedMarkerElement) {
          // console.error("AdvancedMarkerElement is not available.");
          return;
        } else {

          let openInfoWindow = null;

          // REMOVE PREVIOUS CLUSTER MARKERS
          if (prevMarkerList) {
            prevMarkerList.setMap(null);
          }
          setPrevMarkerList(null);

          // ADJUST COORDINATES
          const adjustCoordinates = (center, index) => {
            const { lat, lng } = center;
            const offset = 0.000004; // Very small offset value
            return {
              lat: lat + index * offset,
              lng: lng + index * offset
            };
          };

          // TRACK EXISTING MARKERS
          const markersMap = new Map(); // Map to track markers by their unique ID

          // Create an array to hold all the markers
          const markers = propertyList.map((property, index) => {
            let mapCenter = {
              lat: property?.coordinateWgs84?.y ? parseFloat(property?.coordinateWgs84?.y) : 37.7749,
              lng: property?.coordinateWgs84?.x ? parseFloat(property?.coordinateWgs84?.x) : -122.4194
            };

            mapCenter = adjustCoordinates(mapCenter, index);
            // if (property?.hideExactLocation === true) {
            //   // IF LOCATION HIDE IS TRUE THEN SHOW RANDOM LOCATION WITHIN 100 METERS
            //   mapCenter = getRandomPosition(mapCenter, 100);
            // } else { // ADJUST COORDINATES WITH MINOR SHIFT
            //   mapCenter = adjustCoordinates(mapCenter, index);
            // }

            const uniqueId = `${property.id}-${mapCenter.lat.toFixed(6)},${mapCenter.lng.toFixed(6)}`; // Unique ID with fixed decimal precision

            // Check if marker with this uniqueId already exists
            if (!markersMap.has(uniqueId)) {
              const infowindow = new window.google.maps.InfoWindow({
                content: MapCard(property),
                ariaLabel: "Uluru",
              });

              const imgE = document.createElement("img");
              imgE.src = mapMarker1;

              const beachFlagImg = document.createElement('span');
              beachFlagImg.className = `icon ${property?.propertyType?.iconClassName ?? 'flaticon-home'} map-icon`;

              const marker = new AdvancedMarkerElement({
                map: map,
                position: mapCenter,
                content: beachFlagImg ?? imgE
              });

              marker.addListener("click", () => {
                if (openInfoWindow) {
                  openInfoWindow.close();
                }
                infowindow.open({
                  anchor: marker,
                  map,
                });
                openInfoWindow = infowindow;
              });

              markersMap.set(uniqueId, marker); // Store marker with unique ID
            }

            return markersMap.get(uniqueId); // Return the existing marker
          });

          const markerClusterer = new MarkerClusterer({ markers, map });
          setPrevMarkerList(markerClusterer);
        }
      });
    } else {
      if (prevMarkerList) {
        prevMarkerList.setMap(null);
      }
      setPrevMarkerList(null);
    }

    // Cleanup function
    return () => {
      if (prevMarkerList) {
        prevMarkerList.setMap(null);
      }
    };

  }, [map, propertyList]);

  // DRAW FREE HAND
  const drawFreeHand = () => {
    const newPoly = new window.google.maps.Polyline({ map: map, clickable: false });
    setPoly(newPoly);
    window.google.maps.event.addListenerOnce(map, 'mouseup', () => {
      if (drawing && poly) {
        const path = poly.getPath();
        poly.setMap(null);
        const newPolygon = new window.google.maps.Polygon({ map: map, path: path });
        setPoly(null);
        setDrawing(false);
        enable();
      }
    });
  };

  // DISABLE MAP CONTROLS ON DRAWING
  const disable = () => {

    if (isSmallScreen) {
      document.body.style.overflow = 'hidden';
    }


    map.setOptions({
      draggable: false,
      zoomControl: false,
      scrollwheel: false,
      disableDoubleClickZoom: false
    });
  };

  // ENABLE MAP CONTROLS
  const enable = () => {

    if (isSmallScreen) {
      document.body.style.overflow = 'visible';
    }
    map.setOptions({
      draggable: true,
      zoomControl: true,
      scrollwheel: true,
      disableDoubleClickZoom: true
    });
  };

  // HANDLE DRAWING CANCEL
  const handleDrawingCancel = () => {
    setIsDrawing(false);
    setData([])
    setPolylines([])
    setDrawSearchApplied(false)
    // ENABLE MAP CONTROLS
    enable()
    // RESET MAP TO DEFAULT STARTING STATE
    initializeMap({ zoom: map?.zoom, center: map?.center })

  }

  // HANDLE DRAWING START
  const handleDrawClick = () => {
    setIsDrawing(true)
    setDrawing(true);
    disable();

    window.google.maps.event.addDomListener(map.getDiv(), 'mousedown', () => {
      drawFreeHand();
    });
  };
  // HANDLE DRAWING START ON MOUSE DOWN
  const handleMouseDown = (e) => {
    if (isDrawing) {
      setDrawing(true);
      setPath([e.latLng]);
    }
  };

  // HANDLE DRAWING ON MOUSE MOVE
  const handleMouseMove = (e) => {
    if (isDrawing && drawing) {
      setPath((prevPath) => [...prevPath, e.latLng]);
      const newPath = [...path, e.latLng];
      if (poly) {
        poly.setPath(newPath);
      }
    }
  };
  // HANDLE DRAWING STOP AND FINALIZE POLYGON ON MOUSE UP
  const handleMouseUp = () => {
    if (drawing && path.length > 1) {
      finalizePolygon();
    } else {
      if (poly) {
        poly.setMap(null);
      }
      setPoly(null);
      setPath([]);
    }
    setDrawing(false);
  };

  // COMPLETE AND FINALIZE POLYGON
  const finalizePolygon = () => {
    if (poly) {

      poly.setMap(null); // Remove the temporary polyline
    }
    const closedPath = [...path, path[0]]; // Close the path by adding the first point to the end
    const newPoly = new window.google.maps.Polygon({ map: map, path: closedPath, fillColor: '#FF0000', fillOpacity: 0.35, strokeColor: '#FF0000', strokeWeight: 2 });

    // GET BOUNDARY COORDINATES OF POLYGON
    const boundaryCoords = getPolygonBoundaryCoordinates(newPoly);
    setPolylines((prev) => [...prev, boundaryCoords])
    setPoly(null);
    setPath([]);
  };

  // HANLDE JAVASCRIPT EVENTS
  useEffect(() => {
    if (map) {
      const mouseDownListener = window.google.maps.event.addListener(map, 'mousedown', handleMouseDown);
      const mouseMoveListener = window.google.maps.event.addListener(map, 'mousemove', handleMouseMove);
      const mouseUpListener = window.google.maps.event.addListener(map, 'mouseup', handleMouseUp);

      return () => {
        window.google.maps.event.removeListener(mouseDownListener);
        window.google.maps.event.removeListener(mouseMoveListener);
        window.google.maps.event.removeListener(mouseUpListener);
      };
    }
  }, [map, drawing, path]);

  // Function to extract boundary coordinates from a polygon's path with consecutive duplicates removed
  const getPolygonBoundaryCoordinates = (polygon) => {
    const path = polygon.getPath();
    const boundaryCoordinates = [];
    // Add the first point to the boundary coordinates
    boundaryCoordinates.push({ lat: path.getAt(0).lat(), lng: path.getAt(0).lng() });
    // Add the remaining points of the path to the boundary coordinates, removing consecutive duplicates
    for (let i = 1; i < path.getLength(); i++) {
      const point = path.getAt(i);
      const prevPoint = boundaryCoordinates[boundaryCoordinates.length - 1];
      // Only add the point if it is different from the previous one
      if (point.lat() !== prevPoint.lat || point.lng() !== prevPoint.lng) {
        boundaryCoordinates.push({ lat: point.lat(), lng: point.lng() });
      }
    }
    return boundaryCoordinates;
  };

  // HANDLE DRAWING APPLY
  const handleDrawingApply = () => {
    if (polylines && polylines?.length > 0) {
      setData(polylines)
      setIsDrawing(false)
      setDrawing(false);
      enable()
      setDrawSearchApplied(true)
    } else {
      toast.error(t("PLEASE DRAW ANY SHAPE"))
    }
  }

  return (
    <div className='col-xl-7 p-0'>

      <div className="row h-100 overflow-hidden position-relative">
        <div className="col-md-12">
          <div className="half_map_area h-100">
            <div id="map_canvas" className='half_style w-100 '></div>
            {drawSearchApplied ? <div className='draw-btn'> <button onClick={handleDrawingCancel} className=' ud-btn btn btn-dark ms-2'> <RxCross2 className='me-2' size={20} />{t("REMOVE")} </button>
            </div> :
              isDrawing ?
                <div className='draw-btn'>
                  <button className='ud-btn btn-thm me-2' onClick={handleDrawingApply}> {t("APPLY")} </button>
                  <button onClick={handleDrawingCancel} className=' ud-btn btn-white2 me-2 my-1'>{t("CANCEL")} </button>

                </div>
                :
                <div className='draw-btn'>
                  <button onClick={handleDrawClick} className=' ud-btn btn-white2 my-1'><FaPencil className='me-2' /> {t("DRAW")} </button>
                </div>
            }
            <div>
              <p></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MapSearchDemo;


