import React, { useEffect, useState } from 'react'
import NavIcon from "../../../assets/images/mobile-dark-nav-icon.svg";
import logo2 from "../../../assets/images/Amaken-logo2.svg";
import LoginModal from '../../common/LoginModal';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import userImg from "../../../assets/images/resource/defaultuser.png";
import { logoutUser } from '../../../redux/userProfileSlics';
import storage from '../../../helpers/storage';
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';
import { nameBasedRoutes } from '../../../router/public';
function MobileNav({ openMobileMenu, setOpenMobileMenu }) {

	const cookies = new Cookies()
	const { t } = useTranslation()

	const { i18n } = useTranslation()

	const navigate = useNavigate()

	const [showLogin, setShowLogin] = useState(false);

	const dispatch = useDispatch()

	const userData = useSelector((state) => state?.user?.userData)

	useEffect(() => {
		// Add a class to the body element when the component mounts
		if (openMobileMenu) {
			document.body.classList.add('mm-wrapper--opened');
			// document.body.classList.add('overflow-hidden');
		} else {
			document.body.classList.remove('mm-wrapper--opened');
			// document.body.classList.remove('overflow-hidden');
			document.body.style = ''
		}
		// Clean up the class when the component unmounts
		return () => {
			document.body.classList.remove('mm-wrapper--opened');
			document.body.classList.remove('overflow-hidden');
			document.body.style = ''
		};
	}, [openMobileMenu])

	// LOGOUT
	const handleLogout = () => {
		// UPDATE IN REDUX STATE
		dispatch(logoutUser());
		// REMOVE TOKEN FROM LOCAL STORAGE
		storage.clearToken();
		localStorage.removeItem('userType');
		localStorage.removeItem('US_token');
		localStorage.removeItem('user-info');
		localStorage.removeItem('refresh-token');
		// navigate(nameBasedRoutes?.home?.path)
	};


	// HANDLE LANGUAGE CHANGE 
	const handleLangSwitch = (lang) => {
		cookies.remove("userLangKey")
		cookies.set("userLangKey", lang)
		setOpenMobileMenu(false)
		i18n.changeLanguage(lang)
	}
	return (
		<div id="page" className="mobilie_header_nav stylehome1" >
			<div className="mobile-menu">
				<div className="header innerpage-style">
					<div className="menu_and_widgets">
						<div className="mobile_menu_bar d-flex justify-content-between align-items-center">
						{/* TOGGLE BUTTON  */}
							<button className="menubar btn" onClick={() => setOpenMobileMenu(true)}>
								<img src={NavIcon} alt="nav-icon" />
							</button>
							<Link className="mobile_logo" to={nameBasedRoutes?.home?.path}>
								<img src={logo2} alt="amaken-logo" />
							</Link>
							{
								userData && Object.keys(userData)?.length !== 0 ?
									<ul className="mb0 d-flex justify-content-center justify-content-sm-end p-0">
										<li className=" user_setting">
											<div className="dropdown">
												<Link
													className="btn"
													to="#"
													data-bs-toggle="dropdown"
													aria-expanded="false"
												>
													{
														userData?.imageUrl && userData?.imageUrl !== '' ? <img
															className="bdrs24 userimg-xs"
															src={userData?.imageUrl ?? userImg}
															alt="user-img"
														/> : <img
															className="bdrs24 userimg-xs"
															src={userImg}
															alt="user-img"
														/>
													}
												</Link>
												<div className="dropdown-menu">
													<div className="user_setting_content">
														<p className="fz12 fw400 ff-heading ">
															{t("WELCOME")}:{" "}
															<span className="fz16 fw600">{userData?.firstName}</span>
														</p>
														<p className="fz14 fw400 ff-heading text-gray mb-1 bb1">
															{t("MANAGE ACCOUNT")}
														</p>
														<Link className="dropdown-item lh0 mb5" to="/favorites">
															<i className="flaticon-protection mr10"></i>
															{t('MY FAVORITES')}
														</Link>
														<Link
															className="dropdown-item lh0 mb5"
															to="/profile-user"
														>
															<i className="flaticon-user mr10"></i>
															{t('MY PROFILE (USER)')}
														</Link>
														<Link
															className="dropdown-item lh0 mb5"
															onClick={(e) => {
																e.preventDefault();
																handleLogout();
															}}
														>
															<i className="flaticon-logout mr10"></i>{t("LOGOUT")}
														</Link>
													</div>
												</div>
											</div>
										</li>
									</ul> :
									<button
										className='btn'
										onClick={() => setShowLogin(true)}
									>
										<span className="icon fz18 far fa-user-circle" />
									</button>}

						</div>
					</div>
				</div>
			</div>
			{/* /.mobile-menu */}
			<nav id="menu" className={`mm-menu mm-menu--offcanvas mm-menu--position-left-front mm-menu--theme-light ${openMobileMenu ? 'mm-menu--opened' : ''}`}>
				<div className='mm-panels'>
					<div className='mm-panel mm-panel--noanimation mm-panel--opened'>
						<div className="mm-navbar"><a className="mm-navbar__title" tabIndex="-1" aria-hidden="true"><span className="">{t('MENU')}</span></a></div>
						<ul className='mm-listview'>
							<li className="mm-listitem">
								<Link className="list-item mm-listitem__text" to="/" onClick={() => setOpenMobileMenu(false)}>
									<span className="title">{t('HOME')}</span>
								</Link>
							</li>
							<li className="mm-listitem">
								<Link className="list-item mm-listitem__text" to="/search-by-amaken-map" onClick={() => setOpenMobileMenu(false)}>
									<span className="title">{t('SEARCH BY AMAKEN MAP')}</span>
								</Link>
							</li>

							<li className="mm-listitem">
								<Link className="list-item mm-listitem__text" to="/agencies" onClick={() => setOpenMobileMenu(false)}>
									<span className="title">{t('AGENCIES')}</span>
								</Link>
							</li>
							<li className="px-3 mobile-menu-btn mb80 mm-listitem">
								<a
									href={process.env.REACT_APP_AGENCY_WEB_URL}
									className="ud-btn btn-thm text-white mb50 fz18 ar-font mm-listitem__text"
								>
									{t('AGENCIES LOGIN')}
									<i className="fal fa-arrow-right-long" />
								</a>
							</li>
							<li className="px-3 mobile-menu-btn fz18 mm-listitem">
								{
									i18n.language === "en" ?
										<Link className="ud-btn btn-thm text-white mb50 fz18 ar-font mm-listitem__text" to="#" onClick={() => { handleLangSwitch('ar') }}>
											عربي 	<i className="fal fa-arrow-right-long" />
										</Link>
										: <Link className="ud-btn btn-thm text-white mb50 fz18 ar-font mm-listitem__text" to="#" onClick={() => { handleLangSwitch('en') }}> English<i className="fal fa-arrow-right-long" />
										</Link>
								}
							</li>
						</ul>
					</div>
				</div>
			</nav>
			{/* OPEN LOGIN MODAL FROM MOBILE MENU */}
			<LoginModal
				show={showLogin}
				close={() => setShowLogin(false)}
			/>
		</div>
	)
}

export default MobileNav
