import Button from 'react-bootstrap/Button';
import React, { useState } from 'react'
import InputText from "../../../components/common/InputText"
import { ResetPasswordSchema } from '../../../validations/ValidationSchema';
import { resetPasswordAPI } from '../../../services/userService';
import { Link, useNavigate } from 'react-router-dom';
import { Form, Formik } from "formik"
import { toast } from "react-toastify";
import Loader from "../../../components/common/Loader";
import { useLocation } from 'react-router-dom';

import logoImg2 from "../../../assets/images/Amaken-logo2.svg"
import forgotBg from "../../../assets/images/icon/login-page-icon.svg"
import { useTranslation } from 'react-i18next';
import LoaderMu from '../../../components/common/LoaderMu';
import { nameBasedRoutes } from '../../../router/public';


export const ResetPassword = () => {

  const { t } = useTranslation()
  const [loading, setLoading] = useState()
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  // GET VERIFICATION KEY FROM URL PARAMS
  const verificationKey = params.get('key');

  // HANDLE SUBMIT
  const handleSubmit = async (value, actions) => {
    submitForm(value)
  }
  // SUBMIT RESET PASSWORD FORM
  const submitForm = async (value) => {

    const password = value.password

    resetPasswordAPI({
      newPassword: password.toString(),
      key: verificationKey,
      panel: 'USER',
    })
      .then(response => {
        setLoading(false)
        toast.success(response?.message)
        //navigate("/forgot-password");
      })
      .catch(error => {
        setLoading(false)
        toast.error(error?.response?.data?.error_description ? error?.response?.data?.error_description : error?.response?.data?.message ?? error?.message)
      })
  }

  return (
    <div className="d-flex flex-column pageContainer p-3 h-100 overflow-auto">
      <LoaderMu loading={loading} />
      <div className="wrapper ovh">
        {/*<div className="preloader" />*/}
        <div className="body_content">
          {/* Our Compare Area */}
          <section className="our-compare pt60 pb60">
            <img className="login-bg-icon wow fadeInLeft" src={forgotBg} alt="Forgot bg" />
            <div className="container">
              <div className="row wow fadeInRight" data-wow-delay="300ms">
                <div className="col-lg-6">
                  <div className="log-reg-form signup-modal form-style1 bgc-white p50 p30-sm default-box-shadow2 bdrs12">
                    <div className="header-logo text-center mb40">
                      <Link to={nameBasedRoutes?.home?.path}>
                        <img className="mb25" src={logoImg2} alt="reset logo" />
                      </Link>
                      <h2>{t('RESET PASSWORD')}</h2>
                    </div>
                    <Formik
                      validationSchema={ResetPasswordSchema}
                      initialValues={
                        {
                          password: "",
                          confirm_password: "",
                        }
                      }
                      onSubmit={(values, actions) => {
                        actions.setSubmitting(false)
                        handleSubmit(values, actions)
                      }}
                    >
                      {({
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        setFieldValue,
                        touched,
                        isValid,
                        errors
                      }) => (
                        <Form>
                          {/* ENTER PASSWORD */}
                          <div className="mb5">
                            <InputText
                              controlId="password"
                              label={t("Enter Password")}
                              value={values?.password}
                              name="password"
                              type="password"
                              maxLength="300"
                              errorsField={errors?.password}
                              touched={touched?.password}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              className="form-control"
                            />
                            <span className="form-label fz12 mb0 fw600 mx10 text-danger" />
                          </div>
                          {/* CONFIRM PASSWORD */}
                          <div className="mb5">
                            <InputText
                              controlId="confirm_password"
                              label={t("ENTER CONFIRM PASSWORD")}
                              value={values?.confirm_password}
                              name="confirm_password"
                              type="password"
                              maxLength="300"
                              errorsField={errors?.confirm_password}
                              touched={touched?.confirm_password}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              className="form-control"
                            />
                            <span className="form-label fz12 mb0 fw600 mx10 text-danger" />
                          </div>
                          <div className="d-grid mb20">
                            <Button
                              variant="primary"
                              onClick={handleSubmit}
                              className="ud-btn btn-thm"
                              type="submit"
                            >
                              {t('RESET PASSWORD')}
                              <i className="fal fa-arrow-right-long" />
                            </Button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}