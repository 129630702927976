import AgencyCard from "../../components/agentSingle/AgencyCard"
import AgentAbout from "../../components/agentSingle/AgentAbout"
import AgentInformation from "../../components/agentSingle/AgentInformation"
import AgentProfileInfo from "../../components/agentSingle/AgentProfileInfo"
import ContactForm from "../../components/agentSingle/ContactForm"
import PropertyListing from "../../components/agentSingle/PropertyListing"
import { Footer } from "../../components/layouts/Footer/Footer"
import { toast } from 'react-toastify';
import { agentViewCountApi, getAgentDetail } from '../../services/agencyService';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from "react"
import LoaderMu from "../../components/common/LoaderMu"
import { useTranslation } from "react-i18next"
import ReactGA4 from "react-ga4"

export const Agentsingle = () => {

  const [agentData, setAgentData] = useState("")
  const {t} = useTranslation()
  const {i18n} = useTranslation()
  const [loading, setLoading] = useState(true)
  const { id } = useParams()

  // FETCH CMS DATA
  const fetchAgentData = () => {
    setLoading(true)
    getAgentDetail(id).then(response => {
      setAgentData(response)
      setLoading(false)
    }).catch((error) => {
      setLoading(false)
      toast.error(
        error?.response?.data?.error_description
          ? error?.response?.data?.error_description
          : error?.response?.data?.message
      );
    })
  }

  // FETCH CMS DATA
  useEffect(() => {
    fetchAgentData()
    window.scrollTo({
      top : 0,
      left : 0,
      behavior:"smooth"
    })
  }, [id,i18n?.language])


  useEffect(()=>{
    if(agentData?.id && agentData?.firstName){
      ReactGA4.event({
        category: "agent_page",
        action: "visit",
        id : Date.now() + agentData?.id,
        item : "Agent page",
        description :  `Agent page accessed by ${agentData.firstName + " " + agentData.lastName}`
      });
    }
 
  },[agentData])

   // FETCH AGENCY DETAILS BY ID
   const agentViewCount = () => {
    setLoading(true)
    let params = id
    agentViewCountApi(params).then(response => {
     
    }).catch((error) => {
      toast.error(
        error?.response?.data?.error_description
          ? error?.response?.data?.error_description
          : error?.response?.data?.message ?? error?.message
      );
    }).finally(()=>{
      setLoading(false)
    })
  }

  useEffect(()=>{
    agentViewCount()
  },[])

  return (
    <>
    {loading?<LoaderMu loading ={loading}/>:<div className="body_content">
      {/* Agent Section Area */}
      <section className="agent-single pt60">
        {/* AGENT PERSONAL INFO */}
        <AgentProfileInfo agentInfo={agentData} />
        <div className="container">
          <div className="row wow fadeInUp" data-wow-delay="300ms">
            <div className="col-lg-8 pr40 pr20-lg">
              <div className="row">
                <div className="col-lg-12">
                  {/* DESCRIPTION ABOUT AGENT OR AGENCY */}
                   <AgentAbout agentInfo={agentData} />
                </div>
              </div>
              <PropertyListing agentId ={id}/>
            </div>
            <div className="col-lg-4">
              <div className="agent-single-form home8-contact-form default-box-shadow1 bdrs12 bdr1 p30 mb30-md bgc-white position-relative">
                <h4 className="form-title mb25">{t("CONTACT FORM")}</h4>
                {/* CONTACT FORM */}
                <ContactForm agentId={agentData?.id}/>
              </div>
              {/* AGENT PERSONAL INFORMATION CARD */}
              <AgentInformation agentInfo={agentData} />
              {/* AGENCY CARD */}
              <AgencyCard agencyDetails = {agentData?.agencyDTO}/>
            </div>
          </div>
        </div>
      </section>
      {/* Our Footer */}
      <Footer />
    </div>}
    </>
    
  )
}
