import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import LoginModal from "../../../components/common/LoginModal";
import logoImg from "../../../assets/images/Amaken-logo.svg";
import logoImg2 from "../../../assets/images/Amaken-logo2.svg";
import logoImg_ar from "../../../assets/images/Amaken-logo-ar.svg";
import logoImg2_ar from "../../../assets/images/Amaken-logo2-ar.svg";
import userImg from "../../../assets/images/resource/defaultuser.png";
import { useDispatch, useSelector } from "react-redux";
import "./header.scss";
import { logoutUser } from "../../../redux/userProfileSlics";
import storage from "../../../helpers/storage";
import { useTranslation } from "react-i18next";
import Cookies from "universal-cookie";


function Header() {

  const { t } = useTranslation()

  const cookies = new Cookies()

  const { i18n } = useTranslation()

  const navigate = useNavigate()

  const [showLogin, setShowLogin] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const dispatch = useDispatch();
  const userData = useSelector((state) => state?.user?.userData);

  useEffect(() => {
    const handleScroll = () => {
      // Check for stricky class
      const headerScrollPos = document.querySelector(".header-nav")
        ?.nextElementSibling?.offsetTop;
      if (window.scrollY > headerScrollPos) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    // Attach the scroll event listener when the component mounts
    window.addEventListener("scroll", handleScroll);
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Execute log out task and remove storage
  const handleLogout = () => {

    dispatch(logoutUser())
    storage.clearToken()
    localStorage.removeItem('userType');
    localStorage.removeItem('US_token');
    localStorage.removeItem('user-info');
    localStorage.removeItem('refresh-token');
    navigate("/")
    // window.location.href = process.env.REACT_APP_WEBSITE_WEB_URL;
  };

  // HANDLE LANGUAGE CHANGE 
  const handleLangSwitch = (lang) => {
    cookies.remove("userLangKey")
    cookies.remove("userLangKey")
    cookies.set("userLangKey", lang)
    i18n.changeLanguage(lang)
  }
  return (
    <header
      className={`header-nav nav-homepage-style at-home2 stricky main-menu animated ${isSticky ? "stricky-fixed slideInDown " : "slideIn"
        }`}
    >
      {/* stricky-fixed */}
      {/* Ace Responsive Menu */}
      <nav className="posr">
        <div className="container maxw1600 posr">
          <div className="row align-items-center justify-content-between">
            <div className="col">
              <div className="d-flex align-items-center justify-content-between">
                {
                  i18n?.language === "en" ? <div className="logos">
                    <Link className="header-logo logo1" to="/">
                      <img src={logoImg} alt="Header Logo" />
                    </Link>
                    <Link className="header-logo logo2" to="/">
                      <img src={logoImg2} alt="Header Logo" />
                    </Link>
                  </div> : <div className="logos">
                    <Link className="header-logo logo1" to="/">
                      <img src={logoImg_ar} alt="Header Logo" />
                    </Link>
                    <Link className="header-logo logo2" to="/">
                      <img src={logoImg2_ar} alt="Header Logo" />
                    </Link>
                  </div>
                }

                {/* Responsive Menu Structure*/}
                <ul
                  id="respMenu"
                  className="ace-responsive-menu mx40 mx10-lg"
                  data-menu-style="horizontal"
                >
                  <li className="visible_list">
                    <Link to="/" className="list-item">
                      {t('HOME')}
                    </Link>
                  </li>
                  <li className="megamenu_style">
                    <Link to="/search-by-amaken-map" className="list-item">
                      {t('SEARCH BY AMAKEN MAP')}
                    </Link>
                  </li>
                  <li className="visible_list">
                    <Link to="/agencies" className="list-item">
                      {t('AGENCIES')}
                    </Link>
                  </li>
                  <li className="visible_list">
                    <Link className="list-item" to="#">
                      <span className="title">{t('PAGES')}</span>
                      <span className="arrow "></span>
                    </Link>
                    <ul className={` ${i18n.language === 'ar' ? 'text-right' : ''} sub-menu`}>
                      <li>
                        <Link to="/about">{t('ABOUT US')}</Link>
                      </li>
                      {/* <li>
                        <Link to="/page-contact">{t('CONTACT')}</Link>
                      </li> */}
                      <li>
                        <Link to="/faq">{t('FAQ')}</Link>
                      </li>
                      {/* <li>
                        <a href="/page-login">{t('AGENCIES SIGN IN')}</a>
                      </li>
                      <li>
                        <Link to="/page-register">{t('AGENCIES SIGN UP')}</Link>
                      </li> */}
                      {/* <li>
                        <Link to="/page-error">404</Link>
                      </li>
                      <li>
                        <Link to="/page-ui-element">UI Elements</Link>
                      </li> */}
                    </ul>
                  </li>
                  <li className="visible_list">
                    {
                      i18n.language === "en" ?
                        <Link className="list-item" to="#" onClick={() => { handleLangSwitch('ar') }}>
                          <span className="title fz16 ar-font">عربي</span>
                        </Link>
                        : <Link className="list-item" to="#" onClick={() => { handleLangSwitch('en') }}> <span className="title fz16 ar-font">English</span>
                        </Link>
                    }
                  </li>
                </ul>
              </div>
            </div>
            {/* <pre>{JSON.stringify(userData,null,2)}</pre> */}
            {userData && Object.keys(userData)?.length !== 0 ? (
              <div className="col-auto">
                <div className="d-flex align-items-center">
                  <div className="text-center text-lg-end header_right_widgets">
                    <ul className="mb0 d-flex justify-content-center justify-content-sm-end p-0">
                      <li className=" user_setting">
                        <div className="dropdown">
                          <Link
                            className="btn"
                            to="#"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            {
                              userData?.imageUrl && userData?.imageUrl !== '' ? <img
                                className="bdrs24 userimg-sm"
                                src={userData?.imageUrl ?? userImg}
                                alt="user-img"
                              /> : <img
                                className="bdrs24 userimg-sm"
                                src={userImg}
                                alt="user-img"
                              />
                            }
                          </Link>
                          <div className={` ${i18n.language === 'ar' ? 'text-right' : ''} dropdown-menu`}>
                            <div className="user_setting_content">
                              <p className="fz12 fw400 ff-heading ">
                                {t("WELCOME")}:{" "}
                                <span className="fz16 fw600">{userData?.firstName}</span>
                              </p>
                              <p className="fz14 fw400 ff-heading text-gray mb-1 bb1">
                                {t("MANAGE ACCOUNT")}
                              </p>
                              <Link className="dropdown-item lh0 mb5" to='/favorites'>
                                <i className="flaticon-protection mr10"></i>
                                {t('MY FAVORITES')}
                              </Link>
                              <Link
                                className="dropdown-item lh0 mb5"
                                to="/profile-user"
                              >
                                <i className="flaticon-user mr10"></i> {t('MY PROFILE (USER)')}
                              </Link>
                              {/* <Link className="dropdown-item lh0 mb5" to="/"><i className="flaticon-user mr10"></i>My Profile (Agent)</Link>
                            <Link className="dropdown-item lh0 mb5" to="/"><i className="flaticon-user mr10"></i>My Profile (Agency)</Link> */}
                              <Link
                                className="dropdown-item lh0 mb5"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleLogout();
                                }}
                              >
                                <i className="flaticon-logout mr10"></i>{t("LOGOUT")}
                              </Link>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <a
                    className="ud-btn add-property menu-btn bdrs60 mx-2 mx-xl-4"
                    href={process.env.REACT_APP_AGENCY_WEB_URL}
                    target="_blank"
                  >
                    {t('AGENCIES LOGIN')}<i className="fal fa-arrow-right-long"></i>
                  </a>
                </div>
              </div>
            ) : (
              <div className="col-auto">
                <LoginModal
                  show={showLogin}
                  close={() => setShowLogin(false)}
                />
                <div className="d-flex align-items-center">
                  <Link
                    className="login-info d-flex align-items-center"
                    role="button"
                    to="/"
                    onClick={(e) => {
                      e.preventDefault();
                      setShowLogin(!showLogin);
                    }}
                  >
                    <i className="far fa-user-circle fz16 me-2" />{" "}
                    <span>{t('SIGN IN')}</span>
                  </Link>
                  <a
                    className="ud-btn add-property menu-btn bdrs60 mx-2 mx-xl-4"
                    href={process.env.REACT_APP_AGENCY_WEB_URL}
                    target="_blank"
                  >
                    {t('AGENCIES LOGIN')}
                    <i className="fal fa-arrow-right-long" />
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;
