import { AGENCY_LIST_API, GOVERNORATE_LIST_API, PROPERTIES_FOR_YOU_API, TRUSTED_AGENCIES_API } from '../constants/paths'
import {axios} from './axios'



// PROPERTY FOR YOU API
export const propertiesForYou = async ()=>{
    const response = await axios.get(PROPERTIES_FOR_YOU_API)
    return response?.data
}

// HOME AGENCY LIST API
export const homeAgencyList = async ()=>{
    const response = await axios.get(AGENCY_LIST_API)
    return response?.data
}

// HOME AGENCY LIST API
export const homeGovernorateList = async ()=>{
    const response = await axios.get(GOVERNORATE_LIST_API)
    return response?.data
}

//HOME TRUSTED AGENCY LISTING
export const getTrustedAgencies = async () => {
    const response =  await axios.get(TRUSTED_AGENCIES_API)
    return response
}