  
import ErrorImg from  "../../assets/images/icon/error-page-img.svg"
import logoImg2 from '../../assets/images/Amaken-logo2.svg'
import { Link } from "react-router-dom"
export const Error404 = () => {

 
  return (
    <div className="wrapper ovh">
 {/* <div className="preloader" />*/}
  {/* Main Header Nav */}
  <header className="header-nav nav-innerpage-style bdrb1 style2 main-menu">
    {/* Ace Responsive Menu */}
    <nav className="posr">
      <div className="container posr menu_bdrt1">
        <div className="row align-items-center justify-content-between">
          <div className="col-auto">
            <div className="d-flex align-items-center justify-content-between">
              <div className="logos">
                <Link className="header-logo logo2" to="/">
                  <img src = {logoImg2} alt="Header Logo" />
                </Link>
              </div>
              {/* Responsive Menu Structure*/}
              <ul
                id="respMenu"
                className="ace-responsive-menu mx40 mx10-lg"
                data-menu-style="horizontal"
              ></ul>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </header>
  <div className="body_content">
    {/* Error/404 Section Area */}
    <section className="our-error">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-6 wow fadeInRight" data-wow-delay="300ms">
            <div className="animate_content text-center text-xl-start">
              <div className="animate_thumb">
              <img   src={ErrorImg} alt="Error Bg" />
              </div>
            </div>
          </div>
          <div
            className="col-xl-5 offset-xl-1 wow fadeInLeft"
            data-wow-delay="300ms"
          >
            <div className="error_page_content mt80 mt50-lg text-center text-xl-start">
              <div className="erro_code">
                <span className="text-thm">40</span>4
              </div>
              <div className="h2 error_title">
                Oops! It looks like you're lost.
              </div>
              <p className="text fz15 mb20">
                The page you're looking for isn't available. Try to search again{" "}
                <br className="d-none d-lg-block" /> or use the go to.
              </p>
              <Link to="/" className="ud-btn btn-dark">
                Go Back To Homepages
                <i className="fal fa-arrow-right-long" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* Our Footer */}
   
  </div>
</div>


  )
}
