import React, { useEffect, useState } from 'react'
import { Footer } from "../../components/layouts/Footer/Footer";
import { useDispatch } from "react-redux";
import {  useLocation, useNavigate } from 'react-router-dom';
import { setHeaderClass } from "../../redux/layoutSlice";
import { getFaqPageContent } from '../../services/cmsPageService';
import { toast } from 'react-toastify';
import { Empty } from 'antd';
import Cookies from 'universal-cookie';
import { useTranslation } from "react-i18next";

export const FaqMobile = () => {

  
  const cookies = new Cookies()

  const { t } = useTranslation()
  const {i18n} = useTranslation()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [pageContent, setPageContent] = useState({})


  const location = useLocation()

  const params = new URLSearchParams(location?.search)

  const navigate = useNavigate()

  const lang = params.get("lang") === "ar" ? "ar" : "en"
    
  // FETCH FAQ DATA
  const fetchCmsData = () => {
    setLoading(true)
    getFaqPageContent("faq-list").then(response => {
      setLoading(false)
      setPageContent(response)
    }).catch((error) => {
      setLoading(false)
      toast.error(
        error?.response?.data?.error_description
          ? error?.response?.data?.error_description
          : error?.response?.data?.message
      );
    })
  
  }

  useEffect(() => {
    cookies.remove("userLangKey")
    cookies.set("userLangKey", lang)
    i18n.changeLanguage(lang)
  }, [lang])

  // FETCH FAQ DATA
  useEffect(() => {
    dispatch(setHeaderClass({ className: "container" }))
    fetchCmsData()
    window.scrollTo({ top: 0, left : 0, behavior: 'smooth' });
  }, [lang])


  return (
    <div className="body_content" dir={lang === "ar"? "rtl" : "ltr"}>
      {/* UI Elements Sections */}
      <section className="breadcumb-section pt60 pb30">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="breadcumb-style1">
                <h2 className="title">{t("FREQUENTLY ASKED QUESTIONS")}</h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* FAQ Section Area */}
      <section className="our-faq pb90 pt-0">
        <div className="container">
          <div className="row wow fadeInUp" data-wow-delay="300ms">
            <div className="col-lg-12">
              <div className="ui-content">
                <h4 className="title">{t("FAQ")} {t("ABOUT")} {t("AGENCY")}</h4>
                <div className="accordion-style1 faq-page mb-4 mb-lg-5">
                
                  <div className="accordion" id="accordionExample">
               
                    {/* Listing of faq */}
                    {
                      pageContent?.length ? pageContent.map((item, index) => {
                        if (index === 0) {
                          return (
                            <div key={index + 1} className="accordion-item" >
                              <h2 className="accordion-header" id={`heading${index}`}>
                                  <button
                                    className="accordion-button"
                                    // className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={`#collapse${index}`}
                                    aria-expanded={index < 1 ? 'true' : 'false'}
                                    // aria-expanded={false}
                                    aria-controls={`collapse${index}`}
                                  >
                                   {item?.question}
                                </button>
                              </h2>
                              <div
                                id={`collapse${index}`}
                                className="accordion-collapse collapse hide"
                                aria-labelledby={`heading${index}`}
                                data-parent="#accordionExample"
                              >
                                <div className="accordion-body">
                                    <div dangerouslySetInnerHTML={{ __html: item?.description }} />
                                </div>
                              </div>
                            </div>
                          );
                        }
                        return null; // or any other default value if needed
                      }) : <Empty description={<span>{t("NO FAQ AVAILABLE")}</span>} />
                    }

                    {/* End  */}
                  </div>
                </div>
              </div>
              <div className="ui-content">
                <h4 className="title">{t("FAQ")} {t("ABOUT")} {t("AGENT")}</h4>
                <div className="accordion-style1 faq-page mb-4 mb-lg-5">
                  <div className="accordion" id="accordionExample2">
                    {/* Listing of faq */}
                    {
                      pageContent?.length ? pageContent.map((item, index) => {

                        console.log(index)
                        if (index === 1) {
                          return (
                            <div key={index + 1} className="accordion-item">
                              <h2 className="accordion-header" id="headingOne">
                                <button
                                  className="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target={`#collapse_${index}`}
                                  aria-expanded="false"
                                  aria-controls={`collapse_${index}`}
                                >
                                    {item?.question}
                                </button>
                              </h2>
                              <div
                                id={`collapse_${index}`}
                                className="accordion-collapse collapse hide"
                                aria-labelledby={`heading${index}`}
                                data-parent="#accordionExample2"
                              >
                                <div className="accordion-body">
                                  <div dangerouslySetInnerHTML={{ __html: item?.description }} />
                                </div>
                              </div>
                            </div>
                          );
                        }
                        return null; // or any other default value if needed
                      }) : <Empty description={<span>{t("NO FAQ AVAILABLE")}</span>} />
                    }
                    {/* End  */}

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Our CTA */}
      {/* <section className="our-cta pt0">
        <div className="cta-banner bgc-f7 mx-auto maxw1600 pt120 pb120 pt60-md pb60-md bdrs12 position-relative mx20-lg">
          <div className="img-box-5">
            <img
              className="img-1 spin-right"
              src="images/about/element-1.png"
              alt=""
            />
          </div>
          <div className="img-box-6">
            <img
              className="img-1 spin-left"
              src="images/about/element-1.png"
              alt=""
            />
          </div>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-7 col-xl-6 wow fadeInLeft">
                <div className="cta-style1">
                  <h2 className="cta-title">
                  {t("NEED HELP? TALK TO OUR EXPERT")}
                  </h2>
                  <p className="cta-text mb-0">
                  {t("TALK TO OUR EXPERTS OR BROWSE THROUGH MORE PROPERTIES")}
                  </p>
                </div>
              </div>
              <div
                className="col-lg-5 col-xl-6 wow fadeInRight"
                data-wow-delay="300ms"
              >
                <div className="cta-btns-style1 d-block d-sm-flex align-items-center justify-content-lg-end">
                  <a href ={`tel:+962791234567`} className="ud-btn btn-dark">
                    <span className="flaticon-call vam pe-2" />
                    {t("CONTACT NUMBER")} 
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <Footer /> */}
      {/* Our Footer */}
    </div>
  );
};
